body{
  font-family: 'Jost',sans-serif !important;
}
.top-bar {
  height: 50px;
  box-shadow: 0 4px 3px -5px #333;
}

.user-size, .cart-size{
  font-size: 20px;
}

.cart-style {
  text-decoration: none;
  color:  #16161a;

}

.badge-cart{
  background-color: #ff211a;
}
.top-bar div:first-child{
  display: flex;
  justify-content: right;
  padding: 3px;
}

.navigation-bar {
  height: 100px;
  padding: 25px;
}
.web-logo img {
  width: 200px;
}

.nav-item {
  padding-left: 10px;
  font-weight: 700;

}

.nav-link{
  width: auto !important;
}


.nav-link:hover {
  color: #ff211a !important;
}

.dropdown-item:hover {
  color: #ff211a !important;
}

.discount-bar {
  height: 50px;
  background-color: #16161a;
  text-align: center;
  color: #fff;
  padding: 12px;
}

.discount-bar a {
  color: #cebd9c;
  text-decoration: none;
  border-bottom: 1px solid #cebd9c;
  padding-bottom: 3px;
  font-weight: 500;
}

.carousel-caption {
  top: 10%;
  transform: translateY(-10%);
}

.carousel-caption p.carousel-header {
  color: #000;
  font-size: 4vw;
  font-weight: bolder;
}

.carousel-caption p.carousel-content {
  color: #000;
  font-size: 2vw;
  text-align: left;
}

.carousel-caption button {
  width: 12vw;
  font-size: 1.1vw;
}

.carousel-item {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.col{
  margin-top: 10px !important;
}

.card {
  border: none !important;
}

.card img {
  border-radius: 0;
}

.badges {
  background-color: #f6f6f6;
}

.services-container{
  padding: 30px;
}
.home-services{
  text-align: center;
  margin: 0px auto;
  padding: 20px;
}

.services-border{
  border-right: 1px solid #e6e6e6;
}
.newsletter {
  padding-top: 30px;
  padding-bottom: 40px;
}

.newstext{
  width: 90%;
  margin-right: 10px;

}

.border-break{
  border-bottom: 1px solid #353535;
}

.btn-news{
  background-color: #ff211a !important;
  border-radius: 0px !important;
  height: 60px !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.btn-login{
  background-color: #ff211a !important;
  border-radius: 0px !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.btn-login:hover{
  background-color: #fff !important;
  color: #000 !important;
  border : 1px solid #ff211a;
}

.login-shadow{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.btn-news:hover{
  background-color: #fff !important;
  color: #000 !important;
}
.form-newsletter {
  width: 50vw;
  display: flex;
  justify-content: center;
}

.form-newsletter input {
  border: 1px solid #acacac;
  border-radius: 0;
}

.subs-btn {
  border-radius: 0 !important;
}

.copy-right{
  padding-top: 30px;
  padding-bottom: 5px;
  margin: 0px auto;
  text-align: center;
  color: #b0aeab;
}

.footdesign {
  background-color: #171717;
  color: #fff;
}

.afooter {
  display: block;
  color: #fff;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-weight: bolder;
}

.ulfooter {
  list-style-type: none;
  margin-left: -10px;
  padding: 0;
  overflow: hidden;
}

.login-links{
  text-decoration: none;
  font-weight :500;
  color: #444444;
}

.login-links:hover{
  text-decoration: underline;
}

/* Image Hover */
.collection-container {
  position: relative;

}

.container-image {
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  position: absolute;
  top: 83%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.cart-view{
  width: 100px;
  margin: 0px auto;
  height: 50px;
  display: flex;
  transition: 0.5s ease;
  opacity: 0;
}

.cart-btn, .view-btn{
  width: 50%;
  height: 100%;
  border-radius: 0px !important;
  background-color: #fff !important;
}

.cart-btn:hover, .view-btn:hover{
  background-color: #ff211a !important;
  color: #fff !important;
  border-color: #ff211a !important;
}


.text-col{
  color: #fff;

}

.text-col button{
  transition: 0.5s ease;
  opacity: 0;
}
.collection-container:hover .container-image {
  opacity: 0.6;
}

.collection-container:hover .text-col button {
  opacity: 1;
}

.collection-container:hover .cart-view {
  opacity: 1;
}

.home-productdetails{
  text-align: center;
}

.home-productdetails s{
  color: #ff211a;
}

.cart-s s{
  color: #ff211a;
}
.collection text {
  background-color: #04aa6d;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.card-headcustom{
  display: flex;
  justify-content: space-between;
  background-color: #fff !important;
}

.card-headcustom ul li a{
  color: #000;
}
/* End of Image Hover */

.page-title{
  padding:30px;
  text-align: center;
  background-color: #f3ead8;
}

.gmap {
  max-width: 100%;
  list-style: none;
  transition: none;
  overflow: hidden;
  width: 750px;
  height: 500px;
}
.regcom{
  height:200px;
  display: table;
}

.regcom p{
  text-align:center; 
  vertical-align: middle;
  display: table-cell;
}

.logout{
  margin-top: 2px;
  height: 30px;
  background-color:#000;
  color: #fff;
}

.logout:hover{
  background-color:#333;
  color: #fff;
}

@media only screen and (max-width: 1200px) {
.navigation-bar {
  height: auto !important;
  padding: 5px !important;
}


.web-logo img {
  width: 20vw;
}

.discount-bar {
  display: none !important;
}
}



@media only screen and (max-width: 499px) {
  .table-checkout{
    width: 500px;
  }
}

@media only screen and (max-width: 990px) {

  .web-logo img {
    width: 30vw;
  }
  .carousel-caption p.carousel-header {
    margin-top: 15px;
    font-size: 3vw;
    font-weight: bolder;
  }

  .carousel-caption p.carousel-content {
    color: #000;
    font-size: 2vw;
    margin-top: -10px;
  }

  .carousel-caption button.btn {
    width: 14vw;
    font-size: 1.2vw;
    margin-top: -10px;
    padding: 0px auto;
  }

  .newsletter {
    text-align: center;
  }

  .form-newsletter {
    width: 100vw;
  }

  .card-headcustom{
    display: flex;
    flex-direction: column;
  }

  .services-border{
    border-right: none !important;
    border-bottom: 1px solid #e6e6e6;
  }
}


.cart-photo{
  width: 50px;
  height: 50px;

}

.qty-button {
  margin: 5px;
  color: #000;
  border: none;
  cursor: pointer;
  font-size: 12px;
  width: 20px;
  text-align: center;
  transition: background-color 0.2s;
}

.cart-box{
  width:100%;
  padding: 150px 0;
}

.cart-box-small{
  width:100%;
  padding: 20px 0;
  text-align: center;
}

.cart-big{
  margin-bottom: 100px;
}
.cart-table{
  border: #fff !important;
}

.order-form{
  margin: 0px auto;
  width: 50%;
}

.details-view{
  padding: 10px;
}

.head-email{
  text-decoration: none;
  color: #000;
  font-weight: 700;
}

.update-detail{
  color : #ff211a;
  font-weight: bold;
}

.checkout-arrow{
  color : #ff211a;
  font-weight: bold;
}
